import React from 'react'
import { Remarkable } from 'remarkable'

import { Container, Navigation } from '../components'

import { DefaultLayout } from '../layouts/defaultlayout'

const flatten = (text, child) => {
  return typeof child === 'string' ? text + child : React.Children.toArray(child.props.children).reduce(flatten, text)
}

interface MarkDownProps {
  content: string
  logo: any
}

const menu = [{ title: 'Home', path: '/' }]

const MarkDown = ({ content, logo }: MarkDownProps) => (
  <DefaultLayout>
    <Navigation title="Anjawirth" menu={menu} logo={logo} />
    <Container>
      <div dangerouslySetInnerHTML={{ __html: new Remarkable().render(content) }} />
    </Container>
  </DefaultLayout>
)

export { MarkDown }
