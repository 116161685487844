import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PrivacyPolicyFragment, LogoFragment } from '../fragments'

import { MarkDown } from './_markdown'

export const query = graphql`
  query {
    privacypolicy: directus {
      ...PrivacyPolicyFragment
    }
    logo: directus {
      ...LogoFragment
    }
  }
`

// eslint-disable-next-line import/no-default-export
export default ({ data }) => (
  <>
    <Helmet>
      <meta name="description" content="Datenschutzerklärung von Anja Wirth" />
    </Helmet>
    <MarkDown content={data.privacypolicy.privacypolicy.text} logo={data.logo.logo.logo} />
  </>
)
